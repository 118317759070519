<template>
  <div
    class="main"
    v-loading="loading"
    element-loading-text="上传中，请等待..."
  >
    <heads></heads>
    <banners></banners>
    <islogin />
    <div class="content">
      <div class="cons">
        <div class="cons-title">论文优化</div>
        <shuoming :smdata="smdata" :srcList="srcList"></shuoming>
        <div class="cons-form">
          <el-form
            :model="form"
            label-width="120px"
            :rules="baseRules"
            ref="ruleFormRef"
          >
            <div class="custom-block">
              标题、摘要、关键词为必填信息。
            </div>
            <el-form-item label="标题" required prop="btword">
              <el-input v-model="form.btword" placeholder="请输入标题（5-100个字符）" @keyup.enter="onSubmit(ruleFormRef)" />
            </el-form-item>
            <el-form-item label="摘要" required prop="zyword">
              <el-input
                v-model="form.zyword"
                type="textarea"
                placeholder="请输入摘要（150-2000个字符）"
                @keyup.ctrl.enter="onSubmit(ruleFormRef)" 
              />
            </el-form-item>
            <el-form-item label="关键词" required prop="kwword">
              <el-input
                v-model="form.kwword"
                placeholder="请输入关键词，中间以逗号或空格或分号隔开（5-100个字符）"
                 @keyup.enter="onSubmit(ruleFormRef)" 
              />
            </el-form-item>
            <div class="custom-block">以下为选填信息。</div>
            <el-form-item label="作者单位">
              <el-input v-model="form.writerorg" placeholder="请输入作者单位"  @keyup.enter="onSubmit(ruleFormRef)" />
            </el-form-item>
            <el-form-item label="正文">
              <el-input
                v-model="form.zwword"
                placeholder="请输入正文"
                type="textarea"
                 @keyup.ctrl.enter="onSubmit(ruleFormRef)" 
              />
            </el-form-item>
            <div class="con-btn">
              <el-button type="primary" @click="onSubmit(ruleFormRef)"
                >立即提交</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// 图片
let smdata = reactive([
  { url: "/img/shuoming/02-01.png", tips: "提交信息" },
  { url: "/img/shuoming/02-02.png", tips: "点击立即优化" },
  { url: "/img/shuoming/02-03.png", tips: "得到结果" },
]);
let srcList = reactive([
  "/img/shuoming/02-01.png",
  "/img/shuoming/02-02.png",
  "/img/shuoming/02-03.png",
]);
// loading
let loading = ref(false);
let sbzczyval = ref("");
let form = reactive({
  btword: "",
  zyword: "",
  kwword: "",
  zwword: "",
  ckwx: "",
  writer: "",
  writerorg: "",
  jjkt: "",
  diqu: "",
  fbyt: "",
  sbzczy: "",
  sbzcjb: "",
  subxk: "",
});
const arealist = [
  { Id: 1, name: "安徽省" },
  { Id: 2, name: "北京市" },
  { Id: 3, name: "重庆市" },
  { Id: 4, name: "福建省" },
  { Id: 5, name: "广东省" },
  { Id: 6, name: "甘肃省" },
  { Id: 7, name: "广西区" },
  { Id: 8, name: "河南省" },
  { Id: 9, name: "黑龙江" },
  { Id: 10, name: "湖南省" },
  { Id: 11, name: "吉林省" },
  { Id: 12, name: "江苏省" },
  { Id: 13, name: "辽宁省" },
  { Id: 14, name: "宁夏省" },
  { Id: 15, name: "青海省" },
  { Id: 16, name: "山东省" },
  { Id: 17, name: "山西省" },
  { Id: 18, name: "陕西省" },
  { Id: 19, name: "天津市" },
  { Id: 20, name: "新疆省" },
  { Id: 21, name: "云南省" },
  { Id: 22, name: "浙江省" },
  { Id: 23, name: "上海市" },
  { Id: 24, name: "西藏省" },
  { Id: 25, name: "江西省" },
  { Id: 26, name: "四川省" },
  { Id: 27, name: "海南省" },
  { Id: 28, name: "河北省" },
  { Id: 29, name: "湖北省" },
  { Id: 30, name: "贵州省" },
  { Id: 31, name: "内蒙古" },
  { Id: 32, name: "其他" },
  { Id: 33, name: "台湾省" },
  { Id: 34, name: "港澳" },
];
const zczylist = [
  {
    value: "高等学校教师",
    label: "高等学校教师",
  },
  {
    value: "哲学社会科学研究人员",
    label: "哲学社会科学研究人员",
  },
  {
    value: "自然科学研究人员",
    label: "自然科学研究人员",
    children: [
      {
        value: "自然科学研究",
        label: "自然科学研究",
      },
      {
        value: "科学传播",
        label: "科学传播",
      },
    ],
  },
  {
    value: "卫生技术人员",
    label: "卫生技术人员",
    children: [
      {
        value: "医疗类",
        label: "医疗类",
      },
      {
        value: "药学类",
        label: "药学类",
      },
      {
        value: "护理类",
        label: "护理类",
      },
      {
        value: "技术类",
        label: "技术类",
      },
      {
        value: "基层定向",
        label: "基层定向",
      },
    ],
  },
  {
    value: "工程技术人员",
    label: "工程技术人员",
  },
  {
    value: "农业技术人员",
    label: "农业技术人员",
    children: [
      {
        value: "农艺",
        label: "农艺",
      },
      {
        value: "兽医",
        label: "兽医",
      },
      {
        value: "畜牧",
        label: "畜牧",
      },
      {
        value: "基层",
        label: "基层",
      },
    ],
  },
  {
    value: "新闻专业人员",
    label: "新闻专业人员",
    children: [
      {
        value: "记者",
        label: "记者",
      },
      {
        value: "编辑",
        label: "编辑",
      },
    ],
  },
  {
    value: "出版专业人员",
    label: "出版专业人员",
    children: [
      {
        value: "编审",
        label: "编审",
      },
    ],
  },
  {
    value: "图书资料专业人员",
    label: "图书资料专业人员",
    children: [
      {
        value: "图书资料",
        label: "图书资料",
      },
      {
        value: "群众文化",
        label: "群众文化",
      },
    ],
  },
  {
    value: "文物博物专业人员",
    label: "文物博物专业人员",
  },
  {
    value: "档案专业人员",
    label: "档案专业人员",
  },
  {
    value: "工艺美术专业人员",
    label: "工艺美术专业人员",
  },
  {
    value: "技工院校教师",
    label: "技工院校教师",
    children: [
      {
        value: "文化技术理论课",
        label: "文化技术理论课",
      },
      {
        value: "生产实习课",
        label: "生产实习课",
      },
    ],
  },
  {
    value: "体育专业人员",
    label: "体育专业人员",
    children: [
      {
        value: "教练员",
        label: "教练员",
      },
      {
        value: "运动防护师",
        label: "运动防护师",
      },
    ],
  },
  {
    value: "翻译专业人员",
    label: "翻译专业人员",
  },
  {
    value: "播音主持专业人员",
    label: "播音主持专业人员",
  },
  {
    value: "会计人员",
    label: "会计人员",
  },
  {
    value: "统计专业人员",
    label: "统计专业人员",
  },
  {
    value: "经济专业人员",
    label: "经济专业人员",
    children: [
      {
        value:
          "工商管理、农业经济、财政税收、金融、保险、运输经济、旅游经济、建筑与房地产经济",
        label:
          "工商管理、农业经济、财政税收、金融、保险、运输经济、旅游经济、建筑与房地产经济",
      },
      {
        value: "人力资源管理",
        label: "人力资源管理",
      },
      {
        value: "知识产权",
        label: "知识产权",
      },
    ],
  },
  {
    value: "实验技术人才",
    label: "实验技术人才",
  },
  {
    value: "中等职业学校教师",
    label: "中等职业学校教师",
    children: [
      {
        value: "文化课、专业课教师",
        label: "文化课、专业课教师",
      },
      {
        value: "实习指导教师",
        label: "实习指导教师",
      },
    ],
  },
  {
    value: "中小学教师",
    label: "中小学教师",
  },
  {
    value: "艺术专业人员",
    label: "艺术专业人员",
    children: [
      {
        value: "演员",
        label: "演员",
      },
      {
        value: "演奏员",
        label: "演奏员",
      },
      {
        value: "编剧",
        label: "编剧",
      },
      {
        value: "导演（编导）",
        label: "导演（编导）",
      },
      {
        value: "指挥",
        label: "指挥",
      },
      {
        value: "作曲",
        label: "作曲",
      },
      {
        value: "作词",
        label: "作词",
      },
      {
        value: "摄影（摄像）",
        label: "摄影（摄像）",
      },
      {
        value: "舞台美术设计",
        label: "舞台美术设计",
      },
      {
        value: "艺术创意设计",
        label: "艺术创意设计",
      },
      {
        value: "美术（含雕塑、书法、篆刻等）",
        label: "美术（含雕塑、书法、篆刻等）",
      },
      {
        value: "文学创作",
        label: "文学创作",
      },
      {
        value: "演出监督",
        label: "演出监督",
      },
      {
        value: "舞台技术",
        label: "舞台技术",
      },
      {
        value: "录音",
        label: "录音",
      },
      {
        value: "剪辑",
        label: "剪辑",
      },
    ],
  },
  {
    value: "公共法律服务专业人员",
    label: "公共法律服务专业人员",
    children: [
      {
        value: "公证",
        label: "公证",
      },
      {
        value: "司法鉴定（物证类、声像资料、环境损害）",
        label: "司法鉴定（物证类、声像资料、环境损害）",
      },
      {
        value: "司法鉴定（法医）",
        label: "司法鉴定（法医）",
      },
      {
        value: "律师",
        label: "律师",
      },
    ],
  },
  {
    value: "船舶专业技术人员",
    label: "船舶专业技术人员",
    children: [
      {
        value: "驾驶",
        label: "驾驶",
      },
      {
        value: "轮机",
        label: "轮机",
      },
      {
        value: "电子",
        label: "电子",
      },
      {
        value: "引航",
        label: "引航",
      },
    ],
  },
  {
    value: "民用航空飞行技术人员",
    label: "民用航空飞行技术人员",
    children: [
      {
        value: "驾驶",
        label: "驾驶",
      },
      {
        value: "领航",
        label: "领航",
      },
      {
        value: "通信",
        label: "通信",
      },
      {
        value: "机械",
        label: "机械",
      },
    ],
  },
  {
    value: "审计专业人员",
    label: "审计专业人员",
  },
  {
    value: "党校教师",
    label: "党校教师",
    children: [
      {
        value: "市级、区级",
        label: "市级、区级",
      },
      {
        value: "县级",
        label: "县级",
      },
    ],
  },
  {
    value: "技术经纪",
    label: "技术经纪",
    children: [
      {
        value: "自然科学研究人员",
        label: "自然科学研究人员",
      },
      {
        value: "工程技术人员",
        label: "工程技术人员",
      },
    ],
  },
];
const ruleFormRef = ref();

const baseRules = {
  btword: [{ required: true, message: "请输入标题", trigger: "blur" }],
  zyword: [{ required: true, message: "请输入摘要", trigger: "blur" }],
  kwword: [{ required: true, message: "请输入关键词", trigger: "blur" }],
};

const handleChange = (val) => {
  val.forEach((e, i) => {
    if (i == val.length - 1) {
      form.sbzczy += e;
    } else {
      form.sbzczy += e + ",";
    }
  });
};

// 提交代码
const onSubmit = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      loading.value = true;
      post("/journalRecommend/SubPaperOpt", form)
        .then((res) => {
          loading.value = false;
          if (res.code === 200) {
            ElMessage({
              message: "提交成功！",
              duration: 1000,
              onClose: () => {
                router.push({
                  path: "/lwyh/tgfx",
                  query: {
                    id: res.data,
                  },
                });
              },
            });
          } else {
            ElMessage({
              message: "提交失败：" + res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.value = false;
        });
    }
  });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
:deep(.cons-form .el-input__inner) {
  height: 40px;
  font-size: 18px;
}
:deep(.cons-form .el-textarea__inner) {
  min-height: 100px !important;
  font-size: 18px;
}
:deep(.cons-form .el-button) {
  width: 180px;
  height: 50px;
  font-size: 18px;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 10px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 5px solid #409eff;
  margin: 20px 0 20px 0;
  font-size: 18px;
}
.con-btn {
  text-align: center;
}
.cons-title-subtitle {
  color: #999;
  font-size: 14px;
}
.cons-title-subtitle p {
  margin: 0;
  padding: 0;
  text-align: center;
}
</style>
